import React from 'react';
import * as Y from 'yjs';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import { useParams } from 'react-router-dom';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableStyle,
  DropResult,
} from '@hello-pangea/dnd';
import { useListStore, useAccountStore } from './ListStore';

interface ListItemProps {
  item: Y.Map;
  index: number;
  draggableProps?: any;
  dragHandleProps?: any;
  style?: any;
  isDragging?: boolean;
};

const ListItem = (props: ListItemProps) => {
  const {item, index} = props;
  const style = {
    ...props.style,
    marginLeft: `${(item.get('indentation') || 0) * 2}em`,
  };
  const textareaRef = React.useRef(null);
  const [textareaStyle, setTextareaStyle] = React.useState<{height: string}>({height: "1.5em"});
  React.useEffect(() => {
    setTextareaStyle({height: `${textareaRef.current.scrollHeight}px`});
  }, [item.get('text')]);
  return (
    <li ref={props.innerRef} {...props.draggableProps} style={style}>
      <InputGroup className="mb-3">
        <InputGroup.Checkbox type="checkbox" id={`checked-${index}`} checked={item.get('checkedAt') !== 0} onChange={(e) => { if (e.target.checked) { item.set('checkedAt', new Date().getTime()); } else { item.set('checkedAt', 0); }}} />
        <Form.Control ref={textareaRef} style={textareaStyle} as="textarea" value={item.get('text')} onChange={(e) => item.set('text', e.target.value)} />
        {item.get('indentation') ?
          <Button onClick={(e) => item.set('indentation', (item.get('indentation') || 0) - 1)}>
            &lt;&lt;
          </Button>
          :
          <>
          </>
        }
        {props.dragHandleProps ? <Button {...props.dragHandleProps}>Move</Button> : null}
        <Button onClick={(e) => item.set('indentation', (item.get('indentation') || 0) + 1)}>
          &gt;&gt;
        </Button>
      </InputGroup>
    </li>
  );
};

const SortableListItem = (props: {item: Y.Map, index: number}) => {
  return (
    <Draggable draggableId={`${props.index}`} index={props.index} disableInteractiveElementBlocking={true}>
      {(draggableProvided, draggableSnapshot) => (
        <ListItem {...props} innerRef={draggableProvided.innerRef} draggableProps={draggableProvided.draggableProps} dragHandleProps={draggableProvided.dragHandleProps} style={draggableProvided.draggableProps.style} />
      )}
    </Draggable>
  );
};

const positionSorter = (a: Y.Map, b: Y.Map) => {
  if (a.has('position') && b.has('position')) {
    return a.get('position') - b.get('position');
  }
  return 0;
};

const repositionList = (listItems: Y.Array<Y.Map>) => {
  return listItems.toArray().toSorted(positionSorter).map((item: Y.Map, index: number) => {
    item.set('position', index * 100);
    return item;
  });
};

function List() {
  let { id } = useParams();
  const list = useListStore(id!);
  const {lists, loadingLocal: accountLoadingLocal, loadingRemote: accountLoadingRemote} = useAccountStore();
  let title = list.store.metadata.get('title') || '';
  React.useEffect(() => {
    if (!accountLoadingLocal && !list.loadingLocal) {
      let storedList = lists!.toArray().filter((l) => l.get('id') === id);
      if (storedList.length === 0) {
        const newList = new Y.Map();
        newList.set('id', id!);
        newList.set('title', title);
        lists!.push([newList]);
      }
      else {
        storedList[0].set('title', title);
      }
    }
  }, [id, title, accountLoadingLocal, list.loadingLocal, list.loadingRemote]);
  const uncheckedItems = React.useMemo(() => {
    return list.store.listItems.toArray().filter((item: Y.Map) => item.get('checkedAt') === 0).toSorted(positionSorter);
  }, [list.store.listItems.toArray()]);
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    list.store.listItems.doc.transact(() => {
      const movedItem = uncheckedItems[result.source.index];
      let newAfter, newBefore;
      if (result.destination.index < result.source.index) {
        newAfter = uncheckedItems[result.destination.index];
        newBefore = result.destination.index > 0 ? uncheckedItems[result.destination.index - 1] : null;
      }
      else {
        newAfter = result.destination.index + 1 < uncheckedItems.length ? uncheckedItems[result.destination.index + 1] : null;
        newBefore = uncheckedItems[result.destination.index];
      }
      let newPosition = 0;
      if (newBefore === null) {
        newPosition = Math.round(newAfter.get('position') / 2);
      }
      else if (newAfter === null) {
        newPosition = Math.round(newBefore.get('position') + 100);
      }
      else {
        newPosition = Math.round(newAfter.get('position') - (newAfter.get('position') - newBefore.get('position')) / 2);
        if (newPosition === newAfter.get('position') || newPosition === newBefore.get('position')) {
          repositionList(list.store.listItems);
          newPosition = Math.round(newAfter.get('position') - (newAfter.get('position') - newBefore.get('position')) / 2);
        }
      }
      movedItem.set('position', newPosition);
    });
  };
  React.useEffect(() => {
    if (list.store.listItems.length > 0 && !list.store.listItems.get(0).has('position')) {
      list.store.listItems.doc.transact(() => {
        repositionList(list.store.listItems);
      });
    }
  });
  if (list.loadingLocal) {
    return <Spinner />;
  }
  return (
    <Container>
      <div className="mb-3">
        <InputGroup>
          <Form.Control size="lg" type="text" placeholder="Untitled" value={title} onChange={(e) => list.store.metadata.set('title', e.target.value)} />
          <InputGroup.Text>
            {list.loadingLocal || list.loadingRemote ? <Spinner /> : <span style={{color: list.ws.synced ? '#00ff00' : '#ff0000'}}>&#9679;</span>}
          </InputGroup.Text>
          <Button onClick={(e) => {
              navigator.share({
                title: title,
                url: `https://lists.hozac.com/list/${id}`
              });
            }}>
            Share
          </Button>
        </InputGroup>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(droppableProvided, droppableSnapshot) => (
            <ol className="listItems" ref={droppableProvided.innerRef}>
              {uncheckedItems.map((item: Y.Map, index: number) => <SortableListItem key={index} item={item} index={index} />)}
              {droppableProvided.placeholder}
            </ol>
          )}
        </Droppable>
      </DragDropContext>
      <div>
        <Form onSubmit={(e) => {
          e.preventDefault();
          const added = document.getElementById('added') as HTMLInputElement;
          if (added) {
            const newItem = new Y.Map();
            newItem.set('checkedAt', 0);
            newItem.set('text', added.value);
            newItem.set('position', (list.store.listItems.length + 1) * 100);
            list.store.listItems.push([newItem]);
            added.value = '';
          }
        }}>
          <InputGroup className="mb-3">
            <Form.Control type="text" id="added" placeholder="Add an item" />
            <Button type="submit">
              Add
            </Button>
          </InputGroup>
        </Form>
        <hr />
      </div>
      <ul className="listItems">
        {list.store.listItems.toArray().filter((item: Y.Map) => item.get('checkedAt') !== 0).toSorted((a: Y.Map, b: Y.Map) => b.get('checkedAt') - a.get('checkedAt')).map((item: Y.Map, index: number) => <ListItem key={index} item={item} index={index} />)}
      </ul>
    </Container>
  );
}

export default List;
