import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { LinkContainer } from 'react-router-bootstrap';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Container from 'react-bootstrap/Container';
import { useAccountStore } from './ListStore';

function Account() {
  const {accountId} = useAccountStore();

  return (
    <Container>
      <h1>Account</h1>
      <p>ID: {accountId}</p>
      <ButtonGroup>
        <Button onClick={(e) => {
            navigator.share({
              title: "Lists Account",
              url: `https://lists.hozac.com/account/${accountId}`
            });
          }}>
          Share
        </Button>
        <LinkContainer to={uuidv4()}>
          <Button variant="danger" as="a">
            New account
          </Button>
        </LinkContainer>
      </ButtonGroup>
    </Container>
  );
}

export default Account;
