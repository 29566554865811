import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Container from 'react-bootstrap/Container';
import { useParams } from 'react-router-dom';
import { useAccountStore } from './ListStore';

function JoinAccount() {
  let { id } = useParams();
  const {accountId, setAccountId, loadingLocal, loadingRemote} = useAccountStore();
  return (
    <Container>
      <h1>Join account</h1>
      <p>
        Would you like to replace your current account ({accountId}) with {id}?
        You will lose access to your current lists, but gain access to the lists of the new account.
      </p>
      <ButtonGroup>
        <Button variant="danger" onClick={(e) => {
            setAccountId(id!);
          }}>
          Yes
        </Button>
        <LinkContainer to="/list">
          <Button variant="primary" as="a">
            No
          </Button>
        </LinkContainer>
      </ButtonGroup>
    </Container>
  );
}

export default JoinAccount;
