import React from 'react';
import * as Y from 'yjs';
import { WebsocketProvider } from 'y-websocket';
import { IndexeddbPersistence } from 'y-indexeddb';
import { v4 as uuidv4 } from 'uuid';

interface ListStore {
  metadata: Y.Map<string>;
  listItems: Y.Array<Y.Map>;
}

interface InternalList {
  id: string;
  loadingLocal: boolean;
  loadingRemote: boolean;
  ydoc: Y.Doc;
  indexeddb: IndexeddbPersistence;
  ws?: WebsocketProvider;
  store: ListStore;
}

export type ListItem = {
  checkedAt: number;
  indentation?: number;
  text: string;
};

export type ListMetadata = {
  title: string;
};

const lists = new Map<string, InternalList>();

export const useListStore = (id: string) => {
  const [loadingLocal, setLoadingLocal] = React.useState<boolean>(true);
  const [loadingRemote, setLoadingRemote] = React.useState<boolean>(true);
  const [_, forceUpdate] = React.useReducer<number>((x) => x + 1, 0);
  const list = React.useMemo(() => {
    let list: InternalList;
    if (lists.has(id)) {
      list = lists.get(id);
      list.store.metadata.observeDeep((event, transaction) => {
        forceUpdate();
      });
      list.store.listItems.observeDeep((event, transaction) => {
        forceUpdate();
      });
      return list;
    }
    else {
      const ydoc = new Y.Doc();
      const store = {
        metadata: ydoc.getMap<string>('metadata'),
        listItems: ydoc.getArray<Y.Map>('listItems'),
      };
      list = {
        id: id,
        ydoc: ydoc,
        store: store,
        loadingLocal: true,
        loadingRemote: true,
        indexeddb: new IndexeddbPersistence(id, ydoc),
      };
      lists.set(id, list);
    }
    list.store.metadata.observeDeep((event, transaction) => {
      forceUpdate();
    });
    list.store.listItems.observeDeep((event, transaction) => {
      forceUpdate();
    });
    list.indexeddb.on('synced', () => {
      list.loadingLocal = false;
      setLoadingLocal(false);
      list.ws = new WebsocketProvider('wss://lists.hozac.com/ws', id, list.ydoc);
      list.ws.on('sync', (isSynced: boolean) => {
        list.loadingRemote = !isSynced;
        setLoadingRemote(!isSynced);
      });
    });
    return list;
  }, [id]);
  return list;
};

export type StoredList = {
  id: string;
  title: string;
};

interface Account {
  id: string | null;
  doc: Y.Doc;
  indexeddb: IndexeddbPersistence | null;
  ws: WebsocketProvider | null;
  loadingLocal: boolean;
  loadingRemote: boolean;
  lists: Y.Array<StoredList> | null;
  updaters: (() => void)[];
};

const account: Account = {
  id: null,
  doc: new Y.Doc(),
  indexeddb: null,
  ws: null,
  loadingLocal: true,
  loadingRemote: true,
  lists: null,
  updaters: [],
};

export function useAccountStore() {
  const [accountId, _setAccountId] = React.useState<string>(window.localStorage.getItem('listsAccountId') || "");
  const [loadingLocal, setLoadingLocal] = React.useState<boolean>(account.loadingLocal);
  const [loadingRemote, setLoadingRemote] = React.useState<boolean>(account.loadingRemote);
  const [_, forceUpdate] = React.useReducer<number>((x) => x + 1, 0);
  const setAccountId = (accountId: string) => {
    window.localStorage.setItem('listsAccountId', accountId);
    _setAccountId(accountId);
  };
  React.useEffect(() => {
    if (accountId === "") {
      setAccountId(uuidv4());
      return;
    }
    if (account.id === null) {
      account.id = accountId;
    }
    else if (account.id !== accountId) {
      account.id = accountId;
      account.doc = new Y.Doc();
      account.indexeddb = null;
      account.ws = null;
      account.loadingLocal = true;
      account.loadingRemote = true;
    }
    if (account.lists === null) {
      account.lists = account.doc.getArray<Y.Map>('lists');
      account.lists.observeDeep((event, transaction) => {
        for (const updater of account.updaters) {
          updater();
        }
      });
    }
    if (account.indexeddb === null) {
      account.indexeddb = new IndexeddbPersistence(accountId, account.doc!);
      account.indexeddb.on('synced', () => {
        setLoadingLocal(false);
        account.loadingLocal = false;
        if (account.ws === null) {
          account.ws = new WebsocketProvider('wss://lists.hozac.com/ws', accountId, account.doc!);
          account.ws.on('sync', (isSynced: boolean) => {
            setLoadingRemote(!isSynced);
            account.loadingRemote = !isSynced;
          });
        }
      });
    }
  }, [accountId]);
  React.useEffect(() => {
    account.updaters.push(forceUpdate);
    return () => {
      account.updaters.splice(account.updaters.indexOf(forceUpdate), 1);
    };
  }, [accountId, forceUpdate]);
  return {
    accountId,
    setAccountId,
    lists: account.lists,
    loadingLocal,
    loadingRemote
  };
}
