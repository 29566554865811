import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useAccountStore } from './ListStore';

function Lists() {
  let accountStore = useAccountStore();
  let navigate = useNavigate();
  return (
    <>
      <h1>My lists</h1>
      <Container>
        <Row>
          {accountStore.lists && accountStore.lists.map((list, index: number) => (
            <Col key={list.get('id')} md={2}>
              <Card>
                <Card.Body>
                  <Card.Title>{list.get('title')}</Card.Title>
                </Card.Body>
                <ButtonGroup>
                  <LinkContainer to={`${list.get('id')}`}>
                    <Button as="a">
                      Open
                    </Button>
                  </LinkContainer>
                  <Button variant="danger" onClick={(e) => accountStore.lists!.delete(index, 1)}>
                    Delete
                  </Button>
                </ButtonGroup>
              </Card>
            </Col>
          ))}
          <Col md={2}>
            <Card>
              <Card.Body>
                <Card.Title>New list</Card.Title>
              </Card.Body>
              <ButtonGroup>
                <Button onClick={(e) => {
                    const newId = uuidv4();
                    navigate(`/list/${newId}`);
                  }}>
                  Create
                </Button>
              </ButtonGroup>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Lists;
