import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './App.css';

function App() {
  let navigate = useNavigate();
  return (
    <Container fluid="md">
      <Navbar collapseOnSelect bg="light" expand="md">
        <Container>
          <Navbar.Toggle aria-controls="main-menu" />
          <Navbar.Collapse id="main-menu">
            <Nav className="me-auto">
              <LinkContainer to="list">
                <Nav.Link>My lists</Nav.Link>
              </LinkContainer>
              <Nav.Link onClick={(e) => {
                const newId = uuidv4();
                navigate(`/list/${newId}`);
              }}>New list</Nav.Link>
              <LinkContainer to="account">
                <Nav.Link>Account</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
    </Container>
  );
}

export default App;
